@charset 'utf-8';

// General Tags
a {
    color: #005aa3;
    font-family: 'Lato';
    font-size: 0.9375em;
    font-weight: 700;
    outline: 0;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
	&:hover {
        color: #337bb5;
        text-decoration: none;
	}
}
blockquote {
    background-color: #e6eff6;
    border-color: #005aa3;
    color: #005aa3;
    font-size: 1.1em;
    font-style: italic;
    padding: 30px 30px 30px 110px;
    position: relative;
	i {
        background-color: #005aa3;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        color: #e6eff6;
        font-size: 1.5em;
        height: 60px;
        left: 25px;
        line-height: 60px;
        margin: -30px 0 0 0;
        position: absolute;
        text-align: center;
        top: 50%;
        width: 60px;
	}
}
body {
    color: #303030;
    font-family: 'Lato';
    font-size: 16px;
}
footer {
    background-image: url("../img/bg/footer.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    min-height: 350px;
    position: relative;
    .address {
        float: none;
        text-align: left;
    }
	.footer-home {
        background-color: #101010;
        background-image: none;
        color: #efefef;
        font-size: 0.875em;
        height: auto;
        line-height: 30px;
        min-height: 350px;
        overflow: hidden;
        padding: 60px 0;
        text-align: center;
	}
	p {
        color: #d6d6d6;
        margin-top: 30px;
        &+ul {
            padding-left: 1em;
        }
	}
	.container {
		h3 {
            font-size: 1.25em;
            text-align: left;
            color: #e0e0e0;
		}
		ul {
            list-style: none;
            margin-top: 25px;
            text-align: left;
			li {
				color: #999999;
				a {
					color: #999999;   
				}
			}
            &.address {
                margin-top: 0;
            }
		}
        a.btn.btn-donation {
            color: #ffffff;
            margin-top: 15px;
            &:hover {
                color: #07a939;
            }
        }
        .bassan {
            margin: 0 15px 0 15px;
            width: 92px;
            height: 127px;
        }
        .footer-info {
            margin-bottom: 50px;
        }
        .copyright {
            position: absolute;
        }
    }
}
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #303030;
    font-family: 'Lato';
    margin: 20px 0 10px 0;
}
h4 {
    color: #204560;
    font-size: 18px;
    text-transform: uppercase;
}
p {
    color: #707070;
    font-family: 'Lato';
    font-size: 0.9375em;
    line-height: 1.8em;
    &+ul{
        padding-left: 16px;
    }
	a {
        color: #005aa3;
        font-size: 1em;
        text-decoration: underline;
	}
}
ul {
    line-height: 1.5em;
    padding: 0;
    color: #707070;
}

// About Us
.about-us .tab-content>.tab-pane .rounded {
    border-radius: 50%;
    border: 12px solid rgba(0,0,0,0.075);
}

// Buttons
.btn.btn-donation {
    background-color: #07a939; /* original ea4b4f*/
    border: 2px solid transparent;
    display: inline-block;
    margin: 50px 15px;
    padding: 5px 20px;
	&.donatenow {
		display: block;
		color: #fff;
		margin: 10px 15px;
	}
	&:hover {
		background-color: transparent;
        border-color: #07a939;
        color: #07a939;
	}
}
.btn.btn-dark,
.btn.btn-slider {
    border: 2px solid transparent;
    border: 5px solid transparent;
    background-color: #07a939;
    color: #fff;
    display: inline-block;
    font-size: 1em;
    font-weight: 700;
    margin: 30px 0;
    padding: 10px 40px;
    text-align: center;
    &:hover {
        border-color: #07a939;
        color: #fff;
        background-color: transparent;
    }
}
.btn.btn-slider {
    font-size: 24px;
    margin-top: 50px;
    padding: 10px 40px;
}
.btn.btn-form {
    background-color: #005aa3;
    border: 2px solid transparent;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    color: #fff;
    font-size: 1.1em;
    font-weight: 700;
    margin-top: -20px;
    padding: 15px 40px;
	&:hover {
        background-color: transparent;
        border-color: #005aa3;
        color: #005aa3;
	}
}
.btn.btn-read-more {
    background-color: #548bb8;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    color: #fff;
    padding: 7px 20px;
	&:hover {
		background-color: #005aa3;
	}
}
.btn.btn-make-donation {
    background-color: #07a939;
    border: 2px solid #07a939;
    -webkit-border-radius: 33px;
    border-radius: 33px;
    color: #fff;
    font-size: 1em;
    font-weight: 700;
    height: 66px;
    line-height: 62px;
    margin: 30px 0;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
	&:hover {
        background-color: transparent;
        color: #07a939;
	}
}
.donation-item.done .btn.btn-donation {
	background-color: #c0c0c0;
	border-color: #c0c0c0;
	color: #303030;
	&:hover {
		background-color: #ffffff;
		border-color: #c0c0c0;
		color: #c0c0c0;
	}
}

// Våra Projekt
.donations.readme .btn.btn-donation {
    background-color: #005aa3; /* original ea4b4f*/
    border: 2px solid transparent;
    display: inline-block;
    margin: 50px 15px;
    padding: 5px 20px;
	&.donatenow {
		display: block;
		color: #fff;
		margin: 10px 15px;
	}
	&:before {
		display: none;
	}
	&:hover {
		background-color: transparent;
		border-color: #005aa3;
		color: #005aa3;
	}
}

.donations.readme .btn.btn-dark,
.donations.readme .btn.btn-slider {
    border: 2px solid transparent;
    border: 5px solid transparent;
    background-color: #005aa3;
    color: #fff;
    display: inline-block;
    font-size: 1em;
    font-weight: 700;
    margin: 30px 0;
    padding: 10px 40px;
    text-align: center;
	&:hover {
		border-color: #005aa3;
		background-color: transparent;
		color: #fff;
	}
}

.donations.readme .donation-item:hover {
    border-color: #005aa3;
    -webkit-box-shadow: 0 0 10px #005aa3;
    box-shadow: 0 0 10px #005aa3;
}

.donations.readme .donation-item.done .btn.btn-donation {
	background-color: #c0c0c0;
	border-color: #c0c0c0;
	color: #ffffff;
}

.donations.readme .donation-item.done .btn.btn-donation:hover {
    background-color: #ffffff;
	border-color: #c0c0c0;
	color: #c0c0c0;
}

// Categories
.categories li a {
    padding-right: 10px;
    &:hover {
        color: #7d9cb5;
    }
    &.active {
        background: rgba(125,156,181,0.1);
    }
}

// Collapse
.panel-title>a {
    background-color: transparent;
    border: 1px solid #e6e6e6;
    color: #666666;
    display: block;
    font-size: 0.65em;
    font-weight: 500;
    line-height: 28px;
    min-height: 38px;
    padding: 10px;
    width: 100%;
    transition: all 5ms ease-in-out;
	&:hover {
        background-color: transparent;
        border-color: #999999;
        transition: all 5ms ease-in-out;
		i {
			color: #999999;
			transition: all 5ms ease-in-out;
		}
	}
	&:focus {
		text-decoration: none;
	}
	i {
        color: #d6d6d6;
        float: right;
        font-size: 1.2em;
        transition: all 5ms ease-in-out;
	}
}

// List Group
.sidebar .panel.submenu-cont {
	border: none;
    box-shadow: 0 1px 12px rgba(0,0,0,0.05);
}

.sidebar .panel-group .panel-heading {
    margin: 0;
}

.sidebar .panel-group .panel-heading+.panel-collapse>.list-group, .panel-group .panel-heading+.panel-collapse>.panel-body {
    border: 1px solid #f0f0f0;
}

.sidebar .panel-group .panel-heading+.panel-collapse>.list-group {
    line-height: 2em;
}

.sidebar .panel>.list-group .list-group-item, .panel>.panel-collapse>.list-group .list-group-item {
    border: none;
}

.sidebar .list-group-item {
    padding: 6px 15px 6px 0;
    position: relative;
	&.active {
		background-color: #e6eff6;
	}
	i {
		font-size: 1em;
		margin: 0 15px 0 15px;
	}
	a {
		position: relative;
		z-index: 1;
		transition: all .2s ease-in-out;
		left: 0;
		&:hover {
			left: -5px;
			transition: all .2s ease-in-out;
		}
	}
}


// Comments

// Contact
.contactwrap .contact {
    padding: 0 0 30px;
	.map {
		margin-top: 0;
	}
}
.contact a {
	color: #005aa3;
	&:hover {
		color: #337bb5;
	}
}
.contact h5 {
    color: #303030;
    font-size: 1.2em;
    font-weight: 700;
    margin: 0 0 10px;
}
.contact .list-contact {
    line-height: 1.8em;
    list-style: none;
    margin: 0 0 30px 0;
    font-size: 1em;
	i {
        color: #005aa3;
        font-size: 1.1em;
        margin: 0 5px 0 0;
	}
}
.contactwrap .contact .row {
    margin-bottom: 30px;
}

.contact .contact--info {
    font-size: 1em;
}

// Donate Now
.donate-now:before {
    background-color: rgba(0, 0, 0, 0.3);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}

// Donations
.donations h2 {
    color: #303030;
    font-size: 1.875em;
    font-weight: 400;
    text-align: left;
    text-transform: none;
}
.donations .subtitle {
	color: #abb0b3;
	display: block;
	font-size: 0.875em;
	margin: 10px 0 9px 0;
}
.donations .inledning {
    color: #666666;
	display: block;
	font-size: 1.125em;
    font-weight: 300;
	margin: 10px 0 20px 0;
}

// Donation Item
.donation-item:hover {
	border-color: #07a939;
	-webkit-box-shadow: 0 0 10px #07a939;
	box-shadow: 0 0 10px #07a939;
}
.donation-item:hover .people,
.donation-item:hover .time,
.donation-item:hover .views {
	color: #005aa3;
}
.donation-item p {
    color: #999999;
    font-size: 0.95em;
    line-height: 1.5em;
}
.donation-item span {
    color: #999999;
    display: block;
    font-size: 0.875em;
}
.donation-item .progress .progress-bar {
    background-color: #07a939;
    -webkit-box-shadow: none;
    box-shadow: none;
    height: 25px;
}
.donation-item.done {
    position: relative;
}

.donation-item.done:before {
    /* This bit does the rhombus that sits in the top left hand corner */
    content: "";
    display: block;
    position: absolute;
    width:0px;
    height: 0px;
    top: 30px;
    right: -40px;
    
    width: 180px;
    height: 40px;
    
    text-align: center;
    -webkit-transform: rotateZ(45deg);
    -moz-transform: rotateZ(45deg);
    
    /* Rhombus */
    border-width: 0 40px 40px 40px;
    border-style: solid;
    border-color: transparent transparent #005aa3 transparent;
}

/* This does the text "featured" and the gradient that makes it look curved */
.donation-item.done:after {
    display: block;
    position: absolute;
    content: "avslutat";
    font-weight: 600;
    color: #fff;
    top: 60px;
    right: -45px;
    width: 140px;
    height: 28px;
    padding-top: 5px;
    text-transform: uppercase;
    
    background: transparent;
  
    
    -webkit-transform: rotateZ(45deg);
    -moz-transform: rotateZ(45deg);
}

.donations.readme .donation-item.done:hover {
    border-color: #c0c0c0;
	-webkit-box-shadow: 0 0 10px #c0c0c0;
	box-shadow: 0 0 10px #c0c0c0;
}

// Donation Steps

// Events
.events-item>a:hover {
    color: #548bb8;
    text-decoration: underline;
}

// Events Single

// Facts

// Flicker Feed

// Forms

// Gallery
.gallery {
	background-color: #548bb8;
	&.separator:after {
		border-top-color: #548bb8;
	}
}

// Image Thumbnail

// Links Full Width
.links-full-width:hover {
	color: rgba(255, 255, 255, 0.9);
}

// List Arrow

// Map

// Menu Effect

// Header
header {
    min-height: 210px;
    .container h1 {
        color: rgba(255,255,255,0.5);
        line-height: 50px;
        margin-top: 150px;
        font-size: 2.25em;
    }
}

// Navbar
.navbar {
	/*background-color: rgba(85, 171, 38, 0.1);*/
	/*background-color: rgba(0,106,179, 0.2);*/
	/*background-color: rgba(155,101,34, 0.1);*/
	/*background-color: rgba(42,85,19,0.1);*/
    background-color: rgba(0,0,0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    height: 145px;
    width: 100%;
    z-index: 9999;
    position: absolute;
    top: 0;
}
.navbar.navbar-fixed-top .navbar-brand {
    margin: 18px 0 10px 0 !important;
    width: 60px;
    height: auto;
}
.navbar .navbar-brand {
    margin: 25px 0;
    padding: 0;
    width: 5.25em;
}
.container>.navbar-collapse {   
    width: 100%;
    margin-right: -15px !important;
}

// Navbar Navigation
.navbar-nav>li>a {
    color: #fff;
    display: inline-block;
    font-size: 0.875em;
    font-weight: 700;
    line-height: 30px;
    margin: 55px 12px;
    padding: 0;
    position: relative;
    text-transform: uppercase;
	&.btn.btn-donation {
		padding: 5px 10px;
	}
}

// Navbar Toggle
.navbar-toggle {
	border-color: #fff;
	margin: 22px 10px;
    right: 15px;
    top: 5px;
}

// News
.news h2 {
    color: #303030;
    font-size: 1.875em;
    font-weight: 400;
    text-align: left;
    text-transform: none;
}
.news .subtitle {
    color: #abb0b3;
    display: block;
    font-size: 0.875em;
    margin: 10px 0 9px 0;
}
.news .inledning {
    color: #666666;
	display: block;
	font-size: 1.125em;
    font-weight: 300;
	margin: 10px 0 20px 0;
}
.news .featured {
    background-color: #005aa3;
    color: #fff;
    display: block;
    font-weight: 600;
    left: 0;
    padding: 7px 14px;
    position: absolute;
    text-transform: uppercase;
    top: 0;
}
.news-timeline {
    .container{
        h2 {
            color: #717679;
        }
        h3 {
            color: #d0d0d0;
        }
    }
}

// News in Grid / Isotope
.button-group {
    margin-bottom: 40px;
}

.button-group button {
    padding:0.2em 1em;
    border: 2px solid #d6d6d6;
    outline:none;
    color:#303030;
    background:#ffffff;
    cursor: pointer;
}

.button-group button:hover {
    background: #e6e6e6;
}

.button-group button.active {
    background: #e6e6e6;
}

.news .grid {
    width: 100%;
    position: relative;
}

.news.aktuellt .news-item.secondary-news {
    margin: 0 0 30px 0;
    padding: 15px 30px 20px 30px;
    border: 1px solid #f0f0f0;
    box-shadow: 0 1px 12px rgba(0,0,0,.05);
}

.news .grid .gutter-sizer {
    width: 15px;
}

.news.aktuellt .news-item.secondary-news h2 {
    font-size: 24px;
}

.news.aktuellt .news-item.secondary-news figure,
.news.aktuellt .news-item.secondary-news .yt-video {
    margin: -15px -30px 20px -30px;
}

.news.aktuellt .news-item.secondary-news h2:first-of-type {
    margin-top: 5px;
}

.news.aktuellt .news-item:nth-child(2n) {
    /*margin-left: 0.2%;*/
}

.news.aktuellt .news-item:nth-child(2n) {
    /*float: none;*/
}

/* clear fix */
.news .grid:after {
    content: '';
    display: block;
    clear: both;
}

// News Single
.news.news-single h2 {
	margin: 20px 0 20px 0;
}

// Pagination
.pagination li.active a,
.pagination li a:hover {
    border-color: #d2d2d2;
    background-color: #548bb8;
    color: #fff;
}
.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
    background-color: #005aa3;
    border-color: #005aa3;
}

// Partners

// Previous - Next

// Resume

// Revolution Slider

// Sidebar
.sidebar h4:first-child, .sidebar h4.submenu--title {
    margin-top: 0;
}

// Slider Range

// Social
.social li a {
    color: #fff;
    display: block;
    font-size: 1.2em;
    font-weight: 300;
    left: 0;
    line-height: 50px;
    margin: 4px 20px 4px 0;
    padding: 0 0 0 65px;
    position: relative;
    text-transform: uppercase;
    z-index: 1;
    transition: all 0.2s ease-in-out;
}
.social li a:hover {
    left: -5px;
    transition: all 0.2s ease-in-out;
}

// Donate
.donate-banner {
	list-style: none;
}
.donate-banner li a {
    color: #fff;
    display: block;
    font-size: 1.2em;
    font-weight: 300;
    left: 0;
    line-height: 50px;
    margin: 4px 20px 4px 0;
    padding: 0 0 0 65px;
    position: relative;
    text-transform: uppercase;
    z-index: 1;
    transition: all 0.2s ease-in-out;
}
.donate-banner li a:after {
    border: 25px solid transparent;
    border-left-style: solid;
    border-left-width: 20px;
    content: '';
    display: block;
    height: 20px;
    left: 100%;
    position: absolute;
    top: 0;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    width: 20px;
    z-index: 2;
}

.donate-banner li a i {
	background-color: rgba(255, 255, 255, 0.3);
	display: block;
	height: 50px;
	left: 0;
	line-height: 50px;
	position: absolute;
	text-align: center;
	top: 0;
	width: 50px;
}

.donate-banner li a.kkl {
	background-color: #07a939;
}

.donate-banner li a.kkl:after {
	border-left-color: #07a939;
}

// Text Banner
.text-banner {
	list-style: none;
}
.text-banner li a {
	color: #fff;
	display: block;
	font-size: 1.2em;
	font-weight: 300;
	left: 0;
	line-height: 50px;
	margin: 40px 20px 4px 0;
	padding: 25px 40px 25px 35px;
	position: relative;
	z-index: 1;
    border-top: 4px solid #93631b;
    transition: all 0.2s ease-in-out;
}
.text-banner li a h4 {
    color: #303030;
    margin: 0 0 15px 0;
    
}
.text-banner li a p {
    line-height: 28px;
}

.text-banner li a:after {
	border: 25px solid transparent;
	border-left-style: solid;
	border-left-width: 20px;
	content: '';
	display: block;
	height: 20px;
	left: 100%;
	position: absolute;
	top: 6.5%;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	width: 20px;
	z-index: 2;
}
.text-banner li a:hover {
	left: -5px;
    transition: all 0.2s ease-in-out;
}
.text-banner li a i {
	background-color: transparent;
	display: block;
	height: 50px;
	right: 0;
	line-height: 50px;
	position: absolute;
	text-align: center;
	top: 6.5%;
	width: 50px;
    color: #303030;
}

.text-banner li a {
	background-color: #f0f0f0;
}

.text-banner li a:after {
	border-left-color: #f0f0f0;
}

// Back Banner
.backaktuellt-banner {
	list-style: none;
    transition: all 5ms ease-in-out;
}
.backaktuellt-banner>a {
	border: 1px solid #e6e6e6;
    color: #666;
    display: block;
    font-size: .65em;
    font-weight: 500;
    line-height: 28px;
    min-height: 38px;
    padding: 10px;
    width: 100%;
    transition: all 5ms ease-in-out;
}

.backaktuellt-banner>a i {
    color: #d6d6d6;
    font-size: 1.2em;
    float: right;
    transition: all 5ms ease-in-out;
}

.backaktuellt-banner>a:hover {
	border-color: #999;
}

.backaktuellt-banner>a:hover i {
    color: #999;
}

// Donate Now Banner
.donate-banner--now {
	list-style: none;
}
.donate-banner--now li a {
	color: #fff;
	display: block;
	font-size: 1.2em;
	font-weight: 300;
	left: 0;
	line-height: 50px;
	margin: 40px 20px 4px 0;
	padding: 25px 40px 25px 35px;
	position: relative;
	z-index: 1;
    border: 4px solid #07a939;
    background: #07a939;
    border-radius: 12px;
    transition: all 0.2s ease-in-out;
}
.donate-banner--now li a h4 {
    color: #ffffff;
    margin: 0 0 15px 0;
    
}
.donate-banner--now li a p {
    line-height: 28px;
    color: #ffffff;
    font-weight: 500;
}


.donate-banner--now li a:hover {
	left: -5px;
    background: #ffffff;
    box-shadow: 0 0 10px #999999;
    transition: all 0.2s ease-in-out;
}

.donate-banner--now li a:focus {
    outline: dotted thin;
    outline: -webkit-focus-ring-color auto 5px;
    outline-offset: 0;
    left: -5px;
    background: #ffffff;
    box-shadow: 0 0 10px #999999;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
}

.donate-banner--now li a:hover h4,
.donate-banner--now li a:hover p,
.donate-banner--now li a:focus h4,
.donate-banner--now li a:focus p {
    color: #303030;
}

.donate-banner--now li a:hover p {
    font-weight: 300;
}

.donate-banner--now li a i {
	background-color: transparent;
	display: block;
	height: 100px;
	right: 15px;
	line-height: 50px;
	position: absolute;
	text-align: center;
	top: 7%;
	width: 50px;
    font-size: 1.5em;
    color: #ffffff;
    transition: all 0.2s ease-in-out;
}

.donate-banner--now li a:hover i,
.donate-banner--now li a:focus i {
    color: #07a939;
    font-size: 2em;
    right: 20px;
    transition: all 0.2s ease-in-out;
}

.donate-banner--now li a {
	background-color: #07a939;
}

// Modal
.modal.fade{    
    margin-top: 0;
    z-index: 10000;

    iframe {
        width: 100%;
        transition: 500ms all;
    }
}


// Tabs Horizontal
.tabs-horizontal.nav-tabs {
    border: none;
    margin: 35px 0 30px;
}
.tabs-horizontal.nav-tabs>li:first-child {
    margin-left: 0;
}
.tabs-horizontal.nav-tabs>li a {
    border: 1px solid #e3e3e3;
    -webkit-border-radius: 0;
    border-radius: 0;
    color: #999999;
    margin: 0;
    padding: 15px 30px;
    text-transform: uppercase;
}
.tabs-horizontal.nav-tabs>li.active a,
.tabs-horizontal.nav-tabs>li a:hover {
    background-color: #006ab3;
    color: #fff;
}

// Tags

// Team

// Timeline
.timeline:before {
    background-color: #d6d6d6;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    bottom: 0;
    content: '';
    left: 50%;
    margin-left: -2px;
    position: absolute;
    top: 0;
    width: 4px;
}
.timeline .date-separator a {
    background-color: #101010;

    border: 2px solid #fff;
    color: #fff;
    font-size: 1em;
    height: 40px;
    left: 50%;
    line-height: 36px;
    margin: -9px 0 0 -100px;
    position: absolute;
    text-transform: uppercase;
    top: 50%;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    width: 200px;
}
.timeline .date-separator a:hover {
	background-color: #fff;
	border-color: #fff;
	color: #005aa3;
}
.timeline .date-separator span {
    background-color: #005aa3;
    border: 2px solid transparent;
    color: #fff;
    font-size: 1em;
    height: 40px;
    left: 50%;
    line-height: 36px;
    margin: -16px 0 0 -100px;
    position: absolute;
    text-transform: uppercase;
    top: 50%;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    width: 200px;
}
.timeline .date-separator span:hover {
	/*background-color: #202020;
	border-color: #005aa3;
	color: #fff;*/
}
.timeline .timeline-item {
    background-color: rgba(88, 118, 146, 0.3);
    border: 2px solid #005aa3;
    clear: both;
    margin: 10px 0;
    min-width: 66.6667%;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    padding: 20px;
    display: block;
    position: relative;
    text-shadow: none;
    -webkit-transition: all 0.2s linear 0s;
    -moz-transition: all 0.2s linear 0s;
    -o-transition: all 0.2s linear 0s;
    -ms-transition: all 0.2s linear 0s;
    transition: all 0.2s linear 0s;
}
.timeline .timeline-item:hover {
    border-color: #005aa3;
    color: #fff;
    border-style: dotted;
    -webkit-box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
}
.timeline .timeline-item:hover:after {
    background-color: #587692;
    border-color: #005aa3;
    z-index: 100;
}
.timeline .timeline-item:hover:before {
	border-top-color: #005aa3;
}
.timeline .timeline-item:after {
    background-color: #005aa3;
    border: 4px solid #587692;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    content: "";
    display: block;
    height: 15px;
    position: absolute;
    -webkit-transition: all 0.2s linear 0s;
    -moz-transition: all 0.2s linear 0s;
    -o-transition: all 0.2s linear 0s;
    -ms-transition: all 0.2s linear 0s;
    transition: all 0.2s linear 0s;
    width: 15px;
}
.timeline .timeline-item:before {
    border-top: 3px solid #d6d6d6;
    content: "";
    display: block;
    height: 0;
    position: absolute;
    -webkit-transition: all 0.2s linear 0s;
    -moz-transition: all 0.2s linear 0s;
    -o-transition: all 0.2s linear 0s;
    -ms-transition: all 0.2s linear 0s;
    transition: all 0.2s linear 0s;
    width: 86px;
}
.timeline .timeline-item h5 {
    color: #d6d6d6;
    font-size: 1.2em;
    line-height: 1.5em;
    margin-top: 0;
    -webkit-transition: all 0.2s linear 0s;
    -moz-transition: all 0.2s linear 0s;
    -o-transition: all 0.2s linear 0s;
    -ms-transition: all 0.2s linear 0s;
    transition: all 0.2s linear 0s;
}

// Timeline News

// Vertical Tabs

// Youtube Video

// CTA
.fullwidth-cta {
	padding: 40px;
	background: #005aa3;
}

.fullwidth-cta .btn-dark {
	background-color: transparent;
    border-color: #fff;
    color: #fff;
    border-width: 2px;
}

.fullwidth-cta .btn-dark:hover {
	border-color: #fff;
	background-color: #fff;
    color: #005aa3;
}

.btn.btn-alt {
    border: 2px solid #fff;
    background-color: transparent;
	color: #fff;
	display: inline-block;
	font-size: 1em;
	font-weight: 700;
	margin: 30px 0;
	padding: 10px 40px;
	text-align: center;
}

.btn.btn-alt:hover {
    background-color: #fff;
	color: #005aa3;
}

.fullwidth-cta h3 {
	font-size: 2em;
	font-weight: 700;
}

.fullwidth-cta h3, .fullwidth-cta p {
	color: #fff;
}

.bannercontainer p {
    text-shadow: -2px 1px 8px rgba(0,0,0,0.9);
}
// Isotope
.grid-item {
	width: 50%;
}
    
    // Media Queries
    @import "mediaqueries";