@media handheld, only screen and (max-width: 992px) {
    .navbar.navbar-fixed-top .container {
        width: auto !important;
        padding-right: 0;
    }
    .navbar.navbar-fixed-top .container .navbar-collapse .navbar-nav {
        margin-top: 0;
    }
	.navbar .navbar-brand {
		margin: 30px 0;
		width: 4.75em;
	  }
	.navbar-nav {
		margin-top: 15px;
        text-align: center;
        width: auto !important;
	}
	.navbar-nav>li>a {
		margin: 45px 10px;
		font-size: 0.785em;
	}
    footer .container h3,
    footer .address {
        text-align: center !important;
    }
    
    .footer-info {
        min-height: 175px;
    }
}

@media handheld, only screen and (max-width:780px) {
    
}

@media handheld, only screen and (max-width: 768px) {
    .navbar>.container .navbar-brand, .navbar>.container-fluid .navbar-brand {
        /*margin-left: 5px !important;*/
    }
    
    .footer-info {
        min-height: 0;
    }
    .container-fluid>.navbar-collapse, .container>.navbar-collapse {
        background-color: inherit;
        padding: 0;
    }
    .navbar { /* 90 */
        height: 120px;
    }
    .navbar-nav, .navbar-nav>li, .tabs-horizontal.nav-tabs>li {
        width: auto;
        margin: 0;
        text-align: center;
    }
    .navbar-nav>li a {
        border: none;
    }
    .navbar-nav>li a:hover {
        background: transparent;
    }
}

@media handheld, only screen and (max-width: 767px) {
	
    .navbar { 
        height: 95px;
    }
    
    .navbar-nav, .navbar-nav>li, .tabs-horizontal.nav-tabs>li {
        width: 100% !important;
    }
    
    .navbar-nav {
        margin: 0 !important;
        text-align: left !important;
    }

    .navbar .navbar-brand {
        margin: 8px 0 15px 15px;
        width: 4em;
    }
    
    .container-fluid>.navbar-collapse, .container-fluid>.navbar-collapse.in, .container>.navbar-collapse {
        background-color: #444;
        padding: 0;
        margin: 9px 0 0 0 !important;
        right: 0 !important;
        position: absolute;
    }
    
    .navbar-nav>li a:hover {
        background-color: rgba(255,255,255,0.3);
    }
    .navbar-nav>li .btn.btn-donation {
        margin: 0;
    }
        
    .navbar>.container .navbar-brand,
    .navbar>.container-fluid .navbar-brand {
        margin-left: 15px !important
    }
    .navbar-nav>li a,
    .navbar-nav>li a.btn.btn-donation {
        display: block;
        width: 100%;
        padding: 10px 20px;
        margin: 0;
        border-bottom: 1px solid rgba(255, 255, 255, .1)
    }
    .navbar-nav>li a.btn.btn-donation {
        border-bottom: 2px solid #07a939;
    }
    .navbar-nav>li:last-child {
        padding: 0
    }
}

@media handheld, only screen and (max-width: 510px) {
	.navbar {
		
	}
	header h1 {
        line-height: normal;
    }
    .btn.btn-dark, .btn.btn-slider {
        padding: 10px 5px;
    }
    .tabs-horizontal.nav-tabs>li {
        margin-left: 0;
    }
    .bannercontainer p {
        display: none;
    }
}